/* Fittings.css */
.container-wrapper {
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }
  
  .sidebar-container {
    width: 30%; /* Adjust the width as needed */
    margin-top: 4%;
  }
  .sidebar {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 22px;
    h2 {
      text-align: center;
    }
    a {
      font-size: 110%;
    }
  }
  .sdebarelement {
    padding: 5%;
    line-height: 33px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: block;
  }
  .sidebar-element{
    padding: 5%;
    line-height: 33px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: block;
  }
  
.sidebar-title {
  font-size: 1.5rem; /* Default font size */
}

.sidebar-element {
  font-size: 1rem; /* Default font size */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .sidebar-title {
    font-size: 1.2rem; /* Decrease font size for smaller screens */
  }

  .sidebar-element {
    font-size: 0.9rem; /* Decrease font size for smaller screens */
  }
}
  
  .content-container {
    flex-grow: 1;
  }
  .my-5{
    padding-left: 2%;
  }
  .card-body1 {
    align-self: center;
    width: 16rem;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    position: relative;
    margin: 0 10px 20px;
  }
  .card-body {
    align-self: center;
  }
  
  .card-bod1:hover {
    transform: scale(1.05);
  }
  
  @media only screen and (max-width: 600px) {
    .sidebar-container {
      display: none;
    }
  }
  .card{
    padding-right: 0px;
    padding-left: 0px;
  }

  .fitting-description {
    text-align: justify;
  }