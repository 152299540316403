
.mind-blowing-animation {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure the canvas is behind other content */
    background-color: rgb(255, 255, 255); /* Change the background color as needed */
  }
    